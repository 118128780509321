
import { Component, Vue } from 'vue-property-decorator'
import { SubmitInfo } from '@/types/businessData'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'

@Component
export default class Dictionary extends Vue {
  private dicTpyeList = []

  private props = {
    value: 'deptId',
    label: 'deptName',
    children: 'childNode'
  }

  private dialogFormVisible = false
  private title = '新增组织'

  private info: SubmitInfo = {
    dicType: '',
    dicCode: '',
    dicValue: '',
    orderNum: ''
  }

  private rules = {
    dicType: [{ required: true, message: '请输入字典分类', trigger: ['blur'] }],
    dicCode: [{ required: true, message: '请输入字典编码', trigger: ['blur'] }],
    dicValue: [{ required: true, message: '请输入字典值', trigger: ['blur'] }],
    orderNum: [
      { required: true, message: '请输入排序', trigger: ['blur'] },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  private searchInfo = {
    dicType: '',
    dicValue: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '字典分类',
        prop: 'dicType',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '字典编码',
        prop: 'dicCode',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '字典值',
        prop: 'dicValue',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '排序',
        prop: 'orderNum',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getDicTypeList()
    this.getDicList()
  }

  getDicTypeList () {
    this.$axios.get(this.$apis.businessData.selectDicTypeByList).then((res) => {
      this.dicTpyeList = res || []
    })
  }

  searchData (item: any) {
    this.searchInfo.dicType = item.dicType
    this.onSearch()
  }

  submitForm () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.info.dicId
          ? this.$apis.businessData.updateDic
          : this.$apis.businessData.insertDic
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.getDicList()
          this.dialogFormVisible = false
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 数据项列表数据请求
  getDicList () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.businessData.selectDicByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.tableData.loading = false
      this.tableData.data = res.list || []
      this.total = res.total || 0
    }).catch(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getDicList()
  }

  onAdd () {
    this.title = '新增字典'
    this.dialogFormVisible = true
    this.info = {
      dicType: this.searchInfo.dicType || '',
      dicCode: '',
      dicValue: '',
      orderNum: ''
    }
  }

  onUpdate (dicId: string) {
    this.title = '编辑字典'
    this.getDicDetail(dicId)
  }

  getDicDetail (dicId: string) {
    this.$axios.get(this.$apis.businessData.selectDicByDicId, {
      dicId: dicId
    }).then((res) => {
      this.info = res
      this.dialogFormVisible = true
    })
  }
}
